<template>
  <div class="activity-details g-content">
    <div class="ad-title">
      <span class="ad-title-item" @click="$router.go(-1)"><Icon type="ios-arrow-back" />返回活动</span>
    </div>
    <div class="ad-content">
      <!-- <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="选择票种"></Step>
          <Step title="报名信息"></Step>
          <Step title="确认订单"></Step>
        </Steps>
      </div> -->

      <!-- 选择票种 -->
      <!-- <div v-if="curIndex === 0">
        <p style="padding: 10px 0">选择票种</p>
        <Row :gutter="40">
          <Col
            span="12"
            v-for="(item, index) in [1, 1, 1, 1, 1]"
            :key="'mc2' + index"
          >
            <div
              class="m-card2"
              :class="{ active: index === idx, disable: disableIndx === index }"
              @click="setIndex(index)"
            >
              <div class="mc2-l">
                <div class="mc2-l-info">联合团购票</div>
                <div class="mc2-l-txt">￥155</div>
              </div>
              <div class="mc2-line"></div>
              <div class="mc2-r">起购数量：15</div>
            </div>
          </Col>
        </Row>
        <div class="m-table">
          <div class="mt-title">
            <div class="mt-title-item">票种</div>
            <div class="mt-title-item">数量</div>
            <div class="mt-title-item">总计</div>
          </div>
          <div class="mt-line">
            <div class="mt-line-item">单人票</div>
            <div class="mt-line-item">
              <InputNumber :max="10" :min="1" v-model="value1"></InputNumber>
            </div>
            <div class="mt-line-item" style="font-size: 18px; color: #ff5e00">
              <span style="font-size: 14px">￥</span>1,240
            </div>
          </div>
        </div>
        <div class="f-tac">
          <Button style="width: 100px; margin-right: 10px">Cancel</Button>
          <Button type="primary" style="width: 100px" @click="curIndex = 1"
            >下一步</Button
          >
        </div>
      </div> -->

      <!-- 报名信息 -->
      <div v-if="curIndex === 1">
        <div class="admin-add">
          <div class="admin-add-content">
            <Form
              ref="formInline"
              :model="formInline"
              :rules="ruleInline"
              label-position="right"
              :label-width="100"
              @keyup.enter.native="handleSubmit('formInline')"
            >
              <FormItem label="Name：" prop="name">
                <Input
                  v-model="formInline.name"
                  type="text"
                  placeholder="Please enter the name"
                  style="width: 400px"
                />
              </FormItem>
              <FormItem label="Mobile：" prop="tel">
                <Input
                  v-model="formInline.tel"
                  type="text"
                  placeholder="Please enter the mobile"
                  style="width: 400px"
                />
              </FormItem>
              <FormItem label="验证码：" class="service-code" prop="code">
                <Input
                  v-model="formInline.code"
                  type="text"
                  placeholder="请输入验证码"
                  style="width: 400px"
                />
                <Button
                  type="text"
                  v-if="showCode"
                  @click="getCaptcha"
                  class="code-btn1"
                  >Get verification code</Button
                >
                <Button
                  type="text"
                  v-else
                  disabled
                  class="code-btn2"
                  style="color: #ccc"
                  >{{ count }}s后重新获取</Button
                >
              </FormItem>
              <FormItem label="Company：" prop="company">
                <Input
                  v-model="formInline.company"
                  type="text"
                  placeholder="请输入公司名称"
                  style="width: 400px"
                />
              </FormItem>
              <FormItem label="职务：" prop="jobTitle">
                <Input
                  v-model="formInline.jobTitle"
                  type="text"
                  placeholder="Please enter the title"
                  style="width: 400px"
                />
              </FormItem>
              <FormItem label="备注信息：">
                <Input
                  v-model="formInline.info"
                  maxlength="200"
                  show-word-limit
                  :rows="4"
                  type="textarea"
                  placeholder="请输入备注信息"
                  style="width: 400px"
                />
              </FormItem>

              <div class="f-tac">
                <Button style="width: 100px" 
                @click="handleSubmit('formInline')"
                >保存并提交</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <!-- 确认订单 -->
      <!-- <div v-if="curIndex === 2">
          <div class="m-dispaly">
              <div class="md-l"></div>
              <div class="md-r"></div>
          </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validator } from "@/other/validator";

export default {
  name: "activity-join",
  data() {
    return {
      curIndex: 1,
      disableIndx: 4,
      idx: 0,
      value1: 10,

      formInline: {
        name: "",
        jobTitle: "",
        tel: "",
        code: "",
        company: "",
        jobTitle:'',
        info:'',
      },
      ruleInline: {
        name: [{ required: true, message: "Please enter the Name", trigger: "blur" }],
        jobTitle: [
          { required: true, message: "职务不能为空", trigger: "blur" },
        ],
        info: [
          { required: true, message: "备注信息不能为空", trigger: "blur" },
        ],
        company: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        tel: validator.phone,
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
      },
      showCode: true,
      count: "",
      codeKey: "",
      actData:'',
    };
  },
  computed:{
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo:'getUser',
    }),
    actId(){
      return this.$route.query.id;
    },
  },
  created(){
    this.init();
  },
  methods: {
    ...mapActions({
      graphqlPost:'graphqlPostByZXS',
      getMsgCode: "getMsgCode",
      validateCode:'validateCode',
      activityReg:'act/activityReg',
    }),
    init(){
      this.formInline.name = this.userInfo.name;
      this.formInline.phone = this.userInfo.phone;
      this.formInline.company = this.userInfo.company;
      this.formInline.jobTitle = this.userInfo.jobTitle;
      this.getActInfo();
    },
    async getActInfo(){
      let query = `
          query activity($id: Guid!){
            actInfo: activity(id: $id){
              banners
              acvitityType
              regStartAt
              regEndAt
              company{
                name
                id
                logo
              }
              exhibitorId
              exhibitionId
              title
              enabledBuyingTicket
            }
          }`
      let opt = {
          query: query,
          variables:{
            id:this.actId,
          }
      };
      let res = await this.graphqlPost(opt);
      if(res.data.actInfo){
        this.actData= res.data.actInfo;
        console.log(this.actData,'--------')
      }

    },
    setIndex(index) {
      if (index === this.disableIndx) return;
      this.idx = index;
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },

    async handleSubmit(name){
      let valid = await this.validForm(name); // 验证表单
      if (!valid) return;
      let codeValid = await this.validMsgCode(); // 验证码校验
      if (!codeValid) return;
      this.sbumit();
    },
    async sbumit(){
      let body = {
        activityId:this.actId,
        exhibitionId:this.actData.exhibitionId, 
        exhibitorId: this.actData.exhibitorId,
        memberId: this.userInfo.id,
        userId:this.userInfo.userId,
        fullName: this.formInline.name,
        phoneNumber:this.formInline.phone,
        company: this.formInline.company,
        jobTitle: this.formInline.jobTitle,
        remark: this.formInline.info,
      };
      console.log(body,'body');
      let result=await this.activityReg(body)
      if(result.result){ // 注册成功
        this.$Message.success('报名成功');
        setTimeout(() => {
          this.$router.go(-1);
        }, 500);
      } else{  // 注册失败
        this.$Message.error(result.message);
      }
    },
    cancel() {
      this.$refs["formInline"].resetFields();
      this.codeKey = "";
      this.formInline.jobTitle = "";
      this.formInline.name = "";
      this.formInline.tel = "";
    },
    async getCaptcha() {
      this.$refs.formInline.validateField("tel", (val) => {
        if (val !== "") return;
        this.downTime();
      });
    },
    async downTime() {
      const valid = await this.getMsgCode(this.formInline.tel);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async validMsgCode() {
      // 验证code
      if (!this.formInline.code) return false;
      if (!this.codeKey) {
        this.$Message.warning("Get verification code!");
        return false;
      }
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline.code,
      });
      if (!valid) {
        this.$Message.error("验证未通过!");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.activity-details {
  background: #fff;
  margin-top: 20px;
  margin-bottom: 45px;
  border: solid 1px #ededed;
  .ad {
    &-title {
      padding: 18px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #ededed;
      &-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        .ivu-icon {
          display: inline-block;
          padding-right: 4px;
          position: relative;
          top: -1px;
        }
      }
    }
    &-content {
      padding: 29px 100px 40px;
      .m-card2 {
        margin-bottom: 30px;
      }
      .m-table {
        margin: 40px 0;
      }
    }
  }
}

.admin-add {
  &-content {
    padding: 20px 40px 20px 20px;
  }
}
.admin-add {
  width: 600px;
  margin: 0 auto;
  &-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }
  &-content {
    .submit {
      display: flex;
      justify-content: center;
      button {
        margin-left: -75px;
      }
    }
    img {
      width: 150px;
      height: 150px;
    }
  }
  .form-code-item {
    position: relative;
    .form-code-btn {
      position: absolute;
      right: 1px;
      width: 96px;
      top: 2px;
      border: none;
      outline: none;
      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }
  }
}
.service-code {
  position: relative;
  .code-btn1 {
    position: absolute;
    top: 4px;
    right: 50px;
    height: 28px;
    padding: 0;
    @include font_color(#1890ff);
  }
  .code-btn2 {
    position: absolute;
    top: 4px;
    right: 65px;
    height: 28px;
    padding: 0;
  }
}
</style>